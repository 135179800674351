import OneSignal from 'react-onesignal'

export const init = async () => {
    try {
        // await OneSignal.init({
        //     appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        //     allowLocalhostAsSecureOrigin: true,
        // })

        OneSignal.Slidedown.promptPush()
    } catch (err) {
        console.log(err)
    }
}

export const communication_callback = async (event) => {
    const user = event?.detail?.app?.currentUser
    // if (event.type === 'login') {
    //     console.log('login', event)
    //     if (user) {
    //         try {
    //             // Login OneSignal user after logging out
    //             await loginUser(user)

    //             // Add OneSignal logged in user email

    //             await addUserEmail(user)

    //             // Adding push notification subscription to the user
    //             // await OneSignal.User.PushSubscription.optIn()

    //             // Update OneSignal User Profile

    //             // updateUserprofile(currentUser, email, first_name, last_name)
    //             await updateUserprofile(user, false, true, true)
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    // } else if (event.type === 'logout') {
    //     console.log('logout', event)
    //     // Logout OneSignal user before logging in
    //     await logoutUser()
    //     window.location.reload()
    //     // window.OneSignal = false

    //     // await init()
    // }
}
export const loginUser = async (user) => {
    try {
        if (user.customData.uuid) {
            console.log('User UUID', user.customData.uuid)
            const res = await OneSignal.login(user.customData.uuid)
            console.log('OneSignal Login Response', res)
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const logoutUser = async () => {
    try {
        await OneSignal.logout()
    } catch (error) {
        console.error(error)
        return null
    }
}

export const addUserEmail = async (user) => {
    try {
        if (user.customData.email) {
            OneSignal.User.addEmail(user.customData.email)
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const updateUserprofile = async (
    user,
    setEmail = false,
    setFirstName = false,
    setLastName = false
) => {
    console.log('updateUserprofile', setEmail, setFirstName, setLastName)
    if (setEmail) {
        OneSignal.User.addEmail(user.customData.email)
    }

    if (setFirstName && user.customData.firstName) {
        OneSignal.User.addTag('first_name', user.customData.firstName)
    }
    if (setLastName && user.customData.lastName) {
        OneSignal.User.addTag('last_name', user.customData.lastName)
    }

    //plus anything else we can add in there
}

export const createCommunicationSubscriptions = async (
    setMarketing = false,
    setTransactionsal = false
) => {
    //Add subscription code here
}
