import { AUTH_PREFIX_PATH } from 'constants/route.constant'
// let lang="en"
export function getAppConfig(lang) {
    let language = lang || 'en'

    const appConfig = {
        apiPrefix: '/api',
        // authenticatedEntryPath: '/home',
        authenticatedEntryPath: `/${language}/home`,
        // unAuthenticatedEntryPath: `${AUTH_PREFIX_PATH}/sign-in-simple`,
        unAuthenticatedEntryPath: `/${language}/home`, // Add the language parameter
        tourPath: '/',
        locale: 'en',
        enableMock: false,
    }
    return appConfig
}

// export default appConfig
