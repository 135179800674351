import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import extension from './extension'
import basket from './basket'
import loader from './loader'
import dataWallet from './data-wallet-app/index'
import appProvider from './data-wallet-app/currentProviderSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        extension,
        base,
        locale,
        basket,
        loader,
        dataWallet,
        appProvider,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
