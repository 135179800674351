import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enGdpr from './lang/en/gdpr.json'
import enreferrals from './lang/en/referrals.json'
import enHome from './lang/en/home.json'
import enPrivacyPolicy from './lang/en/privacyPolicy.json'
import enTermsAndConditions from './lang/en/termsAndConditions.json'
import enContactUs from './lang/en/contactUs.json'
import enFooter from './lang/en/footer.json'
import enNavBar from './lang/en/navBar.json'
import enSignInSample from './lang/en/signInSample.json'
import enSignUpSample from './lang/en/signUpSample.json'
import enForgotPasswordForm from './lang/en/forgotPasswordForm.json'
import enOurProducts from './lang/en/ourProducts.json'
import enProductDetail from './lang/en/productDetail.json'
import enDWSubscriptions from './lang/en/dwSubscriptions.json'
import enAffiliates from './lang/en/affiliates.json'

import arGdpr from './lang/ar/gdpr.json'
import arreferrals from './lang/ar/referrals.json'
import arHome from './lang/ar/home.json'
import arContactUs from './lang/ar/contactUs.json'
import arPrivacyPolicy from './lang/ar/privacyPolicy.json'
import arTermsAndConditions from './lang/ar/termsAndConditions.json'
import arFooter from './lang/ar/footer.json'
import arNavBar from './lang/ar/navBar.json'
import arSignInSample from './lang/ar/signInSample.json'
import arSignUpSample from './lang/ar/signUpSample.json'
import arForgotPasswordForm from './lang/ar/forgotPasswordForm.json'
import arOurProducts from './lang/ar/ourProducts.json'
import arProductDetail from './lang/ar/productDetail.json'
import arAffiliates from './lang/ar/affiliates.json'

import frHome from './lang/fr/home.json'
import frContactUs from './lang/fr/contactUs.json'
import frGdpr from './lang/fr/gdpr.json'
import frreferrals from './lang/fr/referrals.json'
import frPrivacyPolicy from './lang/fr/privacyPolicy.json'
import frTermsAndConditions from './lang/fr/termsAndConditions.json'
import frFooter from './lang/fr/footer.json'
import frNavBar from './lang/fr/navBar.json'
import frSignInSample from './lang/fr/signInSample.json'
import frSignUpSample from './lang/fr/signUpSample.json'
import frForgotPasswordForm from './lang/fr/forgotPasswordForm.json'
import frOurProducts from './lang/fr/ourProducts.json'
import frProductDetail from './lang/fr/productDetail.json'
import frAffiliates from './lang/fr/affiliates.json'

import deHome from './lang/de/home.json'
import deContactUs from './lang/de/contactUs.json'
import deGdpr from './lang/de/gdpr.json'
import dereferrals from './lang/de/referrals.json'
import dePrivacyPolicy from './lang/de/privacyPolicy.json'
import deTermsAndConditions from './lang/de/termsAndConditions.json'
import deFooter from './lang/de/footer.json'
import deNavBar from './lang/de/navBar.json'
import deSignInSample from './lang/de/signInSample.json'
import deSignUpSample from './lang/de/signUpSample.json'
import deForgotPasswordForm from './lang/de/forgotPasswordForm.json'
import deOurProducts from './lang/de/ourProducts.json'
import deProductDetail from './lang/de/productDetail.json'
import deAffiliates from './lang/de/affiliates.json'
// import appConfig from 'configs/app.config'
import { getAppConfig } from 'configs/app.config'

const appConfig = getAppConfig('en')

const resources = {
    en: {
        home: enHome,
        gdpr: enGdpr,
        referrals: enreferrals,
        privacyPolicy: enPrivacyPolicy,
        termsAndConditions: enTermsAndConditions,
        contactUs: enContactUs,
        footer: enFooter,
        navBar: enNavBar,
        signInSample: enSignInSample,
        signUpSample: enSignUpSample,
        signUpSample: enSignUpSample,
        forgotPasswordForm: enForgotPasswordForm,
        ourProducts: enOurProducts,
        productDetail: enProductDetail,
        dwSubscriptions: enDWSubscriptions,
        affiliates: enAffiliates,
    },
    ar: {
        home: arHome,
        gdpr: arGdpr,
        referrals: arreferrals,
        contactUs: arContactUs,
        privacyPolicy: arPrivacyPolicy,
        termsAndConditions: arTermsAndConditions,
        footer: arFooter,
        navBar: arNavBar,
        signInSample: arSignInSample,
        signUpSample: arSignUpSample,
        forgotPasswordForm: arForgotPasswordForm,
        ourProducts: arOurProducts,
        productDetail: arProductDetail,
        affiliates: arAffiliates,
    },
    fr: {
        home: frHome,
        contactUs: frContactUs,
        gdpr: frGdpr,
        referrals: frreferrals,
        privacyPolicy: frPrivacyPolicy,
        termsAndConditions: frTermsAndConditions,
        footer: frFooter,
        navBar: frNavBar,
        signInSample: frSignInSample,
        signUpSample: frSignUpSample,
        forgotPasswordForm: frForgotPasswordForm,
        ourProducts: frOurProducts,
        productDetail: frProductDetail,
        affiliates: frAffiliates,   
    },
    de: {
        home: deHome,
        contactUs: deContactUs,
        gdpr: deGdpr,
        referrals: dereferrals,
        privacyPolicy: dePrivacyPolicy,
        termsAndConditions: deTermsAndConditions,
        footer: deFooter,
        navBar: deNavBar,
        signInSample: deSignInSample,
        signUpSample: deSignUpSample,
        forgotPasswordForm: deForgotPasswordForm,
        ourProducts: deOurProducts,
        productDetail: deProductDetail,
        affiliates: deAffiliates,
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig?.locale,
    lng: appConfig?.locale,
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    fr: () => import('dayjs/locale/fr'),
    de: () => import('dayjs/locale/de'),
    ar: () => import('dayjs/locale/ar'),
}

export default i18n
